import React, { ReactNode, Children, forwardRef } from 'react'
import styled, { CSSObject } from '@emotion/styled'
import { space, SpaceProps } from 'styled-system'
import Icon, {
  IconNames,
  IconProps,
  StyledIconSVG
} from 'shared/library/atoms/Icon'
import { rgba } from 'polished'

interface InfoBarProps extends SpaceProps {
  children?: ReactNode
  /**
   * Loads an icon.
   */
  icon?: IconNames
  /**
   * variation of InfoBar
   */
  variant?: 'default' | 'error' | 'feature' | 'info'
  size?: 'default' | 'small'
  onClick?: () => void
}

const ICON_COLORS: { [key: string]: IconProps['color'] } = {
  feature: 'gold',
  error: 'error',
  info: 'grey'
}

/**
 * The InfoBar component is used as state of information. Mainly for vouchers.
 * It uses the styled-system to handle all the props related to space.
 * Pass the rest of the props "...other" to the element that should be styled with styled-system.
 * */

const InfoBar = forwardRef<HTMLDivElement, InfoBarProps>(
  (
    {
      icon,
      children,
      variant = 'default',
      onClick,
      size = 'default',
      ...other
    },
    ref
  ) => {
    return (
      <StyledInfoBar
        ref={ref}
        variant={variant}
        size={size}
        onClick={onClick}
        as={onClick ? 'button' : 'span'}
        {...other}
      >
        {icon && (
          <Icon
            name={icon}
            color={variant ? ICON_COLORS[variant] : 'primary'}
            size={size === 'default' ? 32 : 20}
          />
        )}
        {children &&
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          Children.map(children, (child: any) => {
            // Logic to style text if entered as loose text rather than as element
            if (!child?.type) {
              return <StyledText>{child}</StyledText>
            }
            return child
          })}
        {variant === 'feature' && <Icon name="arrow-right" size={24} />}
      </StyledInfoBar>
    )
  }
)
InfoBar.displayName = 'InfoBar'

export default InfoBar

const StyledText = styled.span({
  flex: '1 0 0',
  margin: 0
})

const StyledInfoBar = styled('div')<
  Required<Pick<InfoBarProps, 'variant' | 'size'>>
>(
  ({ theme }) => ({
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    minHeight: 48,
    boxShadow: theme.shadows.primary[0],
    /**
     * When adding child SVG this component should overwrite the color for different states
     */
    svg: {
      '&:nth-of-type(2)': {
        use: {
          color: theme.colors.text.secondary
        }
      }
    }
  }),
  ({ theme, onClick }) =>
    onClick && {
      cursor: 'pointer',
      '&:hover': {
        transition: `${theme.transitionTime[1]}s ease`,
        boxShadow: theme.shadows.primary[1],
        border: `1px solid ${theme.colors.text.secondary}`,
        svg: {
          '&:nth-of-type(2)': {
            use: {
              color: theme.colors.text.primary
            }
          }
        }
      }
    },
  ({ theme, variant }) => {
    const variants = {
      default: {
        color: theme.colors.text.primary,
        backgroundColor: theme.colors.background.primary
      },
      error: {
        color: theme.colors.state.error,
        backgroundColor: rgba(theme.colors.state.error, 0.04)
      },
      feature: {
        color: theme.colors.text.primary,
        backgroundColor: theme.colors.background.primary
      },
      info: {
        color: theme.colors.eola.greys[70],
        backgroundColor: theme.colors.background.secondary
      }
    } satisfies Record<keyof InfoBarProps['variant'], CSSObject>
    return variants[variant]
  },
  ({ theme, size }) => {
    const sizes = {
      default: {
        borderRadius: theme.radii[3],
        border: `1px solid ${theme.colors.border.primary}`,
        padding: theme.mixins.pxSpread([theme.space[3], theme.space[4]]),
        textAlign: 'center',
        [`${StyledText}`]: { ...theme.fontStyle.h6 },
        [`${StyledIconSVG}`]: { opacity: 1 }
      },
      small: {
        borderRadius: theme.radii[2],
        border: 'none',
        padding: theme.mixins.pxSpread([theme.space[4], theme.space[3]]),
        textAlign: 'left',
        [`${StyledText}`]: theme.fontStyle.p3,
        [`${StyledIconSVG}`]: { opacity: 0.3, marginRight: theme.space[2] }
      }
    } satisfies Record<'default' | 'small', CSSObject>
    return sizes[size]
  },
  space
)
