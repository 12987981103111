import React from 'react'
import Link from 'next/link'
import widgetRoutes from 'enums/routes'
import styled from '@emotion/styled'
import Icon from 'shared/library/atoms/Icon'
import { useRouter } from 'next/router'

interface IPreviousPage {
  title: string
  href?: string
}

export interface BreadcrumbProps {
  pages: IPreviousPage[]
}

/**
 * The Breadcrumb navigation provides links to some of the main previousPage
 * within the navigational hierarchy the user navigated through and shows
 * the user's current location in the widget.
 *
 * The last item doesn't need the href because this is alocated for the current page.
 *
 * This is done manually rather than automatic with next/router because not all
 * pages are present in the breadcrumb and some of them need to display
 * different text.
 */

const Breadcrumb = ({ pages }: BreadcrumbProps) => {
  const { query } = useRouter()
  const widgetSlug = query.widgetSlug as string

  return (
    <StyledBreadcrumb aria-label="breadcrumb">
      <ul className="breadcrumb">
        <li>
          <Link href={widgetRoutes.HOME.url({ widgetSlug })} passHref>
            <Icon name="house" size={20} color="primary" />
          </Link>
        </li>
        {pages.map((breadcrumb, index) => (
          <li
            key={breadcrumb.title}
            className="breadcrumb-item"
            // Inform if this is the current page.
            aria-current={index === pages.length - 1 ? 'page' : undefined}
          >
            <StyledRightIcon name="arrow-right" size={12} />
            <span className="breadcrumb-text">
              {breadcrumb.href ? (
                // Provide link to this page.
                <Link href={breadcrumb.href} className="breadcrumb-link">
                  {breadcrumb.title}
                </Link>
              ) : (
                // Output without "<a>" tag if the href is missing. Meant for the current page.
                breadcrumb.title
              )}
            </span>
          </li>
        ))}
      </ul>
    </StyledBreadcrumb>
  )
}

export default Breadcrumb

export const StyledBreadcrumb = styled.nav(({ theme }) => ({
  padding: `0 ${theme.space[4]}px`,
  borderBottom: theme.border,
  background: theme.colors.white,
  width: '100%',
  ...theme.fontStyle.h5,
  '.breadcrumb': {
    display: 'flex',
    alignItems: 'center',
    height: 60,
    padding: 0,
    margin: 0,
    listStyle: 'none',
    ...theme.mixins.centeredContainer(theme.currentMaxWidth)
  },
  '.breadcrumb-item': {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    '&[aria-current="page"]': {
      cursor: 'auto'
    }
  },
  '.breadcrumb-text': {
    ...theme.mixins.ellipsisLineClamp(1)
  },
  '.breadcrumb-link': {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textDecoration: 'none',
    color: theme.colors.text.primary
  },
  [theme.mediaQueries.lg]: {
    marginLeft: 0,
    marginRight: 'auto'
  }
}))

const StyledRightIcon = styled(Icon)(({ theme }) => ({
  minWidth: 15,
  marginLeft: theme.space[1],
  marginRight: theme.space[1]
}))
