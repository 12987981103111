import createQueryParams from 'helpers/createQueryParams'

interface WidgetSlug {
  widgetSlug: string
}

interface OutletSlug {
  outletSlug: string
}

/**
 * Index by nextjs pathname i.e.
 * const route = useRouter().pathname
 */
const widgetRoutes = {
  HOME: {
    id: 'HOME',
    pathname: '/',
    url: ({ widgetSlug }: WidgetSlug) => `/${widgetSlug}`
  },
  STYLEGUIDE: {
    id: 'STYLEGUIDE',
    pathname: '/styleguide',
    url: () => '/styleguide'
  },
  CATEGORY: {
    id: 'CATEGORY',
    url: ({
      widgetSlug,
      categoryId
    }: WidgetSlug & {
      categoryId: string
    }) => `/${widgetSlug}/${categoryId}`
  },
  ACTIVITY: {
    id: 'ACTIVITY',
    url: ({
      widgetSlug,
      categorySlug = defaultSlugs.ACTIVITIES,
      activitySlug,
      date,
      utm_source,
      utm_campaign
    }: WidgetSlug & {
      categorySlug?: string
      activitySlug: string
      date?: string
      utm_source?: string
      utm_campaign?: string
    }) => {
      const queryString = createQueryParams({ date, utm_source, utm_campaign })
      return `/${widgetSlug}/${categorySlug}/${activitySlug}${
        queryString ? `?${queryString}` : ''
      }`
    }
  },
  ACTIVITIES: {
    id: 'ACTIVITIES',
    url: ({ widgetSlug }: WidgetSlug) => `/${widgetSlug}/activities`
  },
  VOUCHERS: {
    id: 'VOUCHERS',
    url: ({ widgetSlug }: WidgetSlug) => `/${widgetSlug}/vouchers`
  },
  RENTAL: {
    id: 'RENTAL',
    url: ({
      widgetSlug,
      rentalSlug
    }: WidgetSlug & {
      rentalSlug: string
    }) => `/${widgetSlug}/rentals/${rentalSlug}`
  },
  RENTALS: {
    id: 'RENTALS',
    url: ({ widgetSlug }: WidgetSlug) => `/${widgetSlug}/rentals`
  },
  VOUCHER: {
    id: 'VOUCHER',
    url: ({
      widgetSlug,
      voucherSlug
    }: WidgetSlug & {
      voucherSlug: string
    }) => `/${widgetSlug}/vouchers/${voucherSlug}`
  },
  MEMBERSHIPS: {
    id: 'MEMBERSHIPS',
    url: ({ widgetSlug }: WidgetSlug) => `/${widgetSlug}/memberships/`
  },
  MEMBERSHIP: {
    id: 'MEMBERSHIP',
    url: ({
      widgetSlug,
      membershipSlug
    }: WidgetSlug & { membershipSlug: string }) =>
      `/${widgetSlug}/memberships/${membershipSlug}`
  },
  CREDIT_VOUCHER: {
    id: 'CREDIT_VOUCHER',
    url: ({ widgetSlug }: WidgetSlug) =>
      widgetRoutes.VOUCHER.url({ widgetSlug, voucherSlug: 'credit-voucher' })
  },
  CHECKOUT: {
    id: 'CHECKOUT',
    url: ({
      widgetSlug,
      directLink,
      name,
      email
    }: WidgetSlug & {
      directLink?: boolean
      name?: string
      email?: string
    }) => {
      return (
        `/` +
        widgetSlug +
        `/checkout` +
        (directLink
          ? `?direct${name ? '&name=${name}' : ''}${email ? '&email=${email}' : ''}`
          : '')
      )
    }
  },
  BASKET: {
    id: 'BASKET',
    url: ({ widgetSlug }: WidgetSlug) => `/${widgetSlug}/basket`
  },
  MENU: {
    id: 'MENU',
    url: ({ widgetSlug }: WidgetSlug) => `/${widgetSlug}/menu`
  },
  ABOUT: {
    id: 'ABOUT',
    url: ({ widgetSlug }: WidgetSlug) => `/${widgetSlug}/about-us`
  },
  POSTCHECKOUT: {
    id: 'POSTCHECKOUT',
    url: ({
      widgetSlug,
      basketId,
      answerSetId,
      disclaimerId,
      bookingInviteId
    }: WidgetSlug & {
      basketId: string
      answerSetId?: string | null
      disclaimerId?: string | null
      bookingInviteId?: string | null
    }) =>
      `/${widgetSlug}/postcheckout/?basketid=${basketId}&answersetid=${answerSetId}&disclaimerid=${disclaimerId}&bookinginviteid=${bookingInviteId}`
  },
  DISCLAIMERS: {
    id: 'DISCLAIMERS',
    url: ({
      widgetSlug,
      basketId,
      answerSetId,
      disclaimerId
    }: WidgetSlug & {
      basketId: string
      answerSetId?: string | null
      disclaimerId?: string | null
    }) =>
      `/${widgetSlug}/disclaimers/?basketid=${basketId}&answersetid=${answerSetId}&disclaimerid=${disclaimerId}`
  },
  QUESTIONPACKS: {
    id: 'QUESTIONPACKS',
    url: ({
      widgetSlug,
      basketId,
      answerSetId,
      disclaimerId
    }: WidgetSlug & {
      basketId: string
      answerSetId?: string | null
      disclaimerId?: string | null
    }) =>
      `/${widgetSlug}/question-packs/?basketid=${basketId}&answersetid=${answerSetId}&disclaimerid=${disclaimerId}`
  },
  OUTLET: {
    id: 'OUTLET',
    url: ({ outletSlug }: OutletSlug) => `/centres/${outletSlug}`
  },
  ACCOMMODATION: {
    id: 'ACCOMMODATION',
    url: ({ widgetSlug }: WidgetSlug) => `/${widgetSlug}/accommodation`
  }
}

export default widgetRoutes

/**
 * This is to hold all of the links that point to the existing app for when we need it.
 *
 * Remember to add the next/link to the sections using these path as <a> if you are replacing
 * the legacyEolaAppRoot with just '/'.
 */
const legacyEolaAppRoot = process.env.NEXT_PUBLIC_EOLA_ROOT + '/'
export const LEGACY_EOLA_ROUTES = {
  BLOG: {
    id: 'BLOG',
    url: () => legacyEolaAppRoot + 'blog'
  },
  BUSINESS_HOME: {
    id: 'BUSINESS_HOME',
    url: () => legacyEolaAppRoot + 'business'
  },
  ENTHUSIASTS_SIGN_IN: {
    id: 'ENTHUSIASTS_SIGN_IN',
    url: () => legacyEolaAppRoot + 'enthusiasts/sign_in'
  },
  ENTHUSIASTS_PASSWORD_NEW: {
    id: 'ENTHUSIASTS_PASSWORD_NEW',
    url: () => legacyEolaAppRoot + 'enthusiasts/password/new'
  },
  PRIVACY_POLICY: {
    id: 'PRIVACY_POLICY',
    url: () => legacyEolaAppRoot + 'privacy-policy'
  },
  SEARCH: {
    id: 'SEARCH',
    url: () => legacyEolaAppRoot + 'search'
  },
  TERMS_CONDITIONS: {
    id: 'TERMS_CONDITIONS',
    url: () => legacyEolaAppRoot + 'terms-and-conditions'
  },
  SETUP: {
    id: 'SETUP',
    url: () => legacyEolaAppRoot + 'business/get-set-up'
  },
  BOOKING: {
    id: 'BOOKING',
    url: () => legacyEolaAppRoot + 'business/booking-scheduling'
  },
  OPERATIONS: {
    id: 'OPERATIONS',
    url: () => legacyEolaAppRoot + 'business/operations'
  },
  MEMBERSHIPS: {
    id: 'MEMBERSHIPS',
    url: () => legacyEolaAppRoot + 'business/memberships'
  },
  ADVENTURE: {
    id: 'ADVENTURE',
    url: () => legacyEolaAppRoot + 'business/adventure-sport'
  },
  FITNESS: {
    id: 'FITNESS',
    url: () => legacyEolaAppRoot + 'business/fitness-and-wellbeing'
  },
  EXPERIENCE: {
    id: 'EXPERIENCE',
    url: () => legacyEolaAppRoot + 'business/experiences-and-attractions'
  },
  ENTERPRISE: {
    id: 'ENTERPRISE',
    url: () => legacyEolaAppRoot + 'business/enterprise'
  },
  ACTIVITIES_AND_EXPERIENCES: {
    id: 'ACTIVITIES_AND_EXPERIENCES',
    url: () => legacyEolaAppRoot + 'business/activities-and-experiences'
  },
  TREK_AND_EXPEDITIONS: {
    id: 'TREK_AND_EXPEDITIONS',
    url: () => legacyEolaAppRoot + 'business/trek-and-expeditions'
  },
  COURSES_AND_LESSONS: {
    id: 'COURSES_AND_LESSONS',
    url: () => legacyEolaAppRoot + 'business/courses-lessons'
  },
  TOURS_AND_ATTRACTIONS: {
    id: 'TOURS_AND_ATTRACTIONS',
    url: () => legacyEolaAppRoot + 'business/tours-and-attractions'
  },
  RENTALS: {
    id: 'RENTALS',
    url: () => legacyEolaAppRoot + 'business/rentals'
  },
  MULTI_SITE: {
    id: 'MULTI_SITE',
    url: () => legacyEolaAppRoot + 'business/multi-site'
  },
  PRODUCT_BUILDER: {
    id: 'PRODUCT_BUILDER',
    url: () => legacyEolaAppRoot + 'business/product-builder'
  },
  BOOKINGS: {
    id: 'BOOKINGS',
    url: () => legacyEolaAppRoot + 'business/bookings'
  },
  INSTRUCTORS: {
    id: 'INSTRUCTORS',
    url: () => legacyEolaAppRoot + 'business/instructors'
  },
  MARKETING: {
    id: 'MARKETING',
    url: () => legacyEolaAppRoot + 'business/marketing'
  },
  REPORTING: {
    id: 'REPORTING',
    url: () => legacyEolaAppRoot + 'business/reporting'
  },
  AI: {
    id: 'AI',
    url: () => legacyEolaAppRoot + 'business/eola-ai'
  },
  PARTNERSHIPS: {
    id: 'PARTNERSHIPS',
    url: () => legacyEolaAppRoot + 'business/partnerships'
  },
  ACADEMY: {
    id: 'ACADEMY',
    url: () => legacyEolaAppRoot + 'academy'
  }
}

/**
 * Index by nextjs pathname i.e.
 * Routes for eola.co (public facing).
 */
export const EOLA_ROUTES = {
  HOME: {
    id: 'HOME',
    url: () => '/'
  },
  ABOUT_US: {
    id: 'ABOUT_US',
    url: () => '/about-us'
  },
  MARKETPLACE: {
    id: 'MARKETPLACE',
    url: () => '/marketplace'
  },
  SEARCH: {
    id: 'SEARCH',
    url: () => '/search'
  }
}

/**
 * This is to hold all of the links that point to the business app for when we need it.
 *
 * Remember to add the next/link to the sections using these path as <a> if you are replacing
 * the legacyBusinessAppRoot with just '/'.
 */
const legacyBusinessAppRoot = process.env.NEXT_PUBLIC_EOLA_BUSINESS_ROOT
export const LEGACY_BUSINESS_ROUTES = {
  JOIN: {
    id: 'JOIN',
    url: () => legacyBusinessAppRoot + 'join'
  },
  OUTLET_NEW: {
    id: 'JOIN',
    url: () => legacyBusinessAppRoot + 'outlets/new'
  }
}

export const defaultSlugs = {
  ACTIVITIES: 'activities'
}
