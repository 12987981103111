import React, {
  ReactNode,
  forwardRef,
  DOMAttributes,
  ForwardedRef
} from 'react'
import styled from '@emotion/styled'
import { ThemeProvider } from '@emotion/react'

interface LayoutProps extends Pick<DOMAttributes<HTMLDivElement>, 'onScroll'> {
  children?: ReactNode
  /**
   * Takes a string to output a class. Related to emotion - It lets another component style it
   */
  className?: string
  /**
   * For layout variations.
   */
  variant?: 'default' | 'wide'
}

export const SIDE_PADDING = 4

const normalTheme = {}
const wideTheme = { currentMaxWidth: 'xl' }

const Layout = (
  { children, className, variant, onScroll }: LayoutProps,
  ref?: ForwardedRef<HTMLDivElement>
) => {
  return (
    <ThemeProvider theme={variant == 'wide' ? wideTheme : normalTheme}>
      <StyledLayout
        className={className}
        variant={variant}
        onScroll={onScroll}
        ref={ref}
      >
        {children}
      </StyledLayout>
    </ThemeProvider>
  )
}

export default forwardRef(Layout)

export const StyledLayout = styled.div<LayoutProps>(({ theme }) => ({
  display: 'flex',
  flex: '1 0 0',
  flexDirection: 'column',
  overflow: 'auto',
  height: '100%',
  background: theme.colors.background.secondary,
  width: '100%',
  /*
   * In older browsers, specifically older versions of Safari, elements
   * were shrinking unexpectedly. Setting flexShrink manually on the child elements
   * was a recommended solution to avoid this. We also apply this to
   * the children of 'main' below. See PR #4704 for more info.
   */
  '& > *': {
    flexShrink: 0
  },
  main: {
    '& > *': {
      flexShrink: 0
    },
    display: 'flex',
    flex: '1 0 auto',
    flexDirection: 'column',
    padding: theme.mixins.pxSpread([
      theme.space[4],
      theme.space[SIDE_PADDING],
      theme.space[4],
      theme.space[SIDE_PADDING]
    ]),
    ...theme.mixins.centeredContainer(theme.currentMaxWidth, SIDE_PADDING),
    // The "position: sticky" doesn't play well with the parent element being "display: flex" (Firefox bug) - https://stackoverflow.com/a/60877878/7883067 - https://github.com/eola/eola/issues/4084
    '@-moz-document url-prefix()': {
      paddingBottom: theme.space[11]
    }
  },
  [theme.mediaQueries.widget]: {
    borderRight: theme.border,
    borderLeft: theme.border
  }
}))
