import { Context, useContext, useEffect } from 'react'
import { useRouter } from 'next/router'
import {
  EnthusiastAuthContext,
  EnthusiastAuthContextProps,
  InstructorAuthContext,
  InstructorAuthContextProps
} from 'shared/providers/AuthProvider'
import { ON_SERVER } from 'shared/enums/environment'
import useDetermineProject from 'shared/state/useDetermineProject'
import { AuthOnProjects, AuthUsersType } from 'shared/enums/auth'

/**
 * Support client-side conditional redirecting based on the instructor's
 * authenticated state and connects to the auth provider to receive necessary data.
 *
 * useAuth holds the logic for all the hooks used for authentication.
 * This file is divided into two sections:
 * - Enthusiast Auth Hook
 * - Instructor Auth Hook
 */

/**
 * It uses the context from the enthusiast or instructor provider to defined if the user is signed in.
 */
const useAuth = <T>({
  auth,
  context
}: {
  auth: AuthUsersType
  context: Context<T>
}): T => {
  const project = useDetermineProject()

  if (project && !AuthOnProjects[auth].projects.includes(project)) {
    throw new Error(
      `The project ${project} is not setup to use the ${auth} authentication.`
    )
  }

  const providerContext = useContext(context)
  if (context === undefined) {
    throw new Error('This auth hook must be used within an auth provider.')
  }

  return providerContext
}

export interface UseUser {
  /**
   * Use redirectTo to declare where the page should be redirected to.
   * e.g. redirectTo: widgetRoutes.HOME(widgetSlug)
   */
  redirectTo?: string
  /**
   * By default, the redirect will be called if the user is 'not-authenticated' but this logic
   * can be overwritten if motiveToRedirect is 'is-authenticated'.
   */
  motiveToRedirect?: 'not-authenticated' | 'is-authenticated'
}

/**
 *
 * Enthusiast Auth Hook
 *
 */
export const useEnthusiast = ({
  redirectTo,
  motiveToRedirect = 'not-authenticated'
}: UseUser = {}) => {
  const authProps = useAuth<EnthusiastAuthContextProps>({
    auth: 'enthusiast',
    context: EnthusiastAuthContext
  })
  const { authLoading, authenticated } = authProps

  useDetermineShouldRedirect({
    authLoading,
    authenticated,
    redirectTo,
    motiveToRedirect
  })

  return authProps
}

/**
 * Instructor Auth Hook
 */
export const useInstructor = ({
  redirectTo,
  motiveToRedirect = 'not-authenticated'
}: UseUser = {}) => {
  const authProps = useAuth<InstructorAuthContextProps>({
    auth: 'instructor',
    context: InstructorAuthContext
  })
  const { authLoading, authenticated } = authProps

  useDetermineShouldRedirect({
    authLoading,
    authenticated,
    redirectTo,
    motiveToRedirect
  })

  return authProps
}

const useDetermineShouldRedirect = ({
  authLoading,
  authenticated,
  redirectTo,
  motiveToRedirect
}: {
  authLoading?: boolean
  authenticated?: boolean
} & UseUser) => {
  const { push } = useRouter()

  useEffect(() => {
    // If user data is not ready then don't do anything yet
    if (authLoading) return

    if (
      !ON_SERVER &&
      redirectTo &&
      // If redirectTo is set, redirect if the user was not authenticated.
      ((motiveToRedirect === 'not-authenticated' && !authenticated) ||
        // If motiveToRedirect is also set, redirect if the user was authenticated
        (motiveToRedirect === 'is-authenticated' && authenticated))
    ) {
      push(redirectTo)
    }
    // TODO #6362 - Fix ESLint - hook
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authLoading, authenticated, redirectTo, motiveToRedirect])
}
