import React, { ReactNode } from 'react'
import { Text } from '../Typography'
import styled from '@emotion/styled'
import { margin, MarginProps } from 'styled-system'
import Flex from 'shared/library/atoms/Flex'

interface FieldsetProps extends MarginProps {
  children: ReactNode

  /**
   * Takes a string to output a class. Related to emotion - It lets another component style it
   */
  className?: string

  /**
   * The legend works a label for fieldset
   */
  legend?: string

  /**
   * Adds red asterisk to end of legend to indicate required information
   */
  required?: boolean
}

/**
 * The <fieldset> element is a convenient way to create groups
 * of widgets that share the same purpose, for styling and semantic purposes.
 *
 * You can add a caption to <fieldset> by using the legend attribute or including a <legend>
 * element just below the opening <Fieldset> tag.
 *
 * It uses styled-system to add margins if necessary, e.g. a section of
 * two inputs in the same row and the second input needs margin-let.
 */

const Fieldset = ({ children, legend, required, ...other }: FieldsetProps) => {
  return (
    <StyledFieldset {...other}>
      {legend && (
        <Flex>
          <legend>
            {legend}
            {required && <StyledAsterisk>{'*'}</StyledAsterisk>}
          </legend>
        </Flex>
      )}
      {children}
    </StyledFieldset>
  )
}

export default Fieldset

export const StyledAsterisk = styled(Text)(({ theme }) => ({
  color: theme.colors.error,
  lineHeight: theme.space[3] + 'px',
  marginLeft: theme.space[2]
}))

const StyledFieldset = styled.fieldset(
  ({ theme }) => ({
    border: 'none',
    padding: 0,
    width: '100%',
    legend: {
      display: 'flex',
      paddingBottom: theme.space[3],
      fontFamily: theme.fonts.heading,
      fontWeight: theme.fontWeights.sm
    },
    'fieldset + &': {
      marginTop: theme.space[4]
    }
  }),
  margin
)
