import React, { ReactNode } from 'react'
import styled from '@emotion/styled'
import { margin, MarginProps } from 'styled-system'
import getSystemProps from 'shared/helpers/getSystemProps'
import Icon from 'shared/library/atoms/Icon'
import { KeyEvents } from 'shared/enums/keys'
import { StyledAsterisk } from '../Fieldset/Fieldset'
import { useField } from 'shared/providers/FormProvider/formProvider'

/**
 * This interface is only meant to be used internally for styling purposes.
 * The following props won't be available to the main component.
 */
export interface CheckboxInternalProps
  extends React.LabelHTMLAttributes<HTMLLabelElement> {
  checked: boolean
  disabled?: boolean
}

interface CheckboxProps extends MarginProps {
  name: string
  label: ReactNode
  disabled?: boolean
  required?: boolean
  /**
   * Takes a string to output a class. Related to emotion - It lets another component style it
   */
  className?: string
}

/**
 * Checkbox is used in place of a traditional `<input type="checkbox" />` element. This has
 * Formik baked into it for ease of use.
 *
 * It uses styled-system to add margins if necessary, e.g. a section of
 * two inputs in the same row and the second input needs margin-let.
 */

const Checkbox = (props: CheckboxProps) => {
  const { label, name, className, required } = props
  const { field, helpers } = useField<boolean>(name)

  /**
   * Allow the 'checkbox' to be checked and unchecked via
   * the space bar, and prevent browser page scroll.
   */
  const handleLabelKeyDown = (e: React.KeyboardEvent) => {
    if (KeyEvents.spacebar === e.key || KeyEvents.enter === e.key) {
      e.preventDefault()
      helpers.setValue(!field.value)
    }
  }

  /**
   * Using the label as a wrapper instead of sibling to the input because InputCheckbox component
   * is a custom input (actual box doesnt respond to click).
   */

  return (
    <StyledLabel
      htmlFor={name}
      role="checkbox"
      checked={field.value}
      aria-checked={field.value}
      tabIndex={0}
      onKeyDown={handleLabelKeyDown}
      {...getSystemProps(props)}
      className={className}
    >
      <InputCheckbox {...props} />
      {label}
      {required && <StyledAsterisk>{'*'}</StyledAsterisk>}
    </StyledLabel>
  )
}

export default Checkbox

export const InputCheckbox = (
  props: Pick<CheckboxProps, 'name' | 'disabled'>
) => {
  const { name, disabled, ...other } = props
  const { field } = useField<boolean>(name)

  return (
    <>
      <StyledCheckboxIcon
        checked={field.value}
        disabled={disabled}
        color="white"
        name="tick"
      />
      <StyledHiddenCheckbox
        id={name}
        {...field}
        {...other}
        value={field.value as unknown as string} // this is not correct - we are actually typing as a boolean but the hidden input is a fundamental hack and is only there to store our value
        type="checkbox"
        checked={field.value}
      />
    </>
  )
}

const StyledLabel = styled.label<CheckboxInternalProps>(
  ({ theme, checked }) => ({
    ...theme.fontStyle.p2,
    color: 'inherit',
    display: 'flex',
    alignItems: 'center',
    flex: '1 0 0',
    marginTop: theme.fixedSpace[3],
    marginBottom: theme.fixedSpace[3],
    cursor: 'pointer',
    /**
     * This is styling for the check symbol, giving space between the label and the
     * checkbox itself and changing color depending on whether it is selected.
     */
    svg: {
      marginRight: theme.space[2],
      use: {
        color: checked ? theme.colors.white : theme.colors.text.secondary
      }
    }
  }),
  margin
)

/**
 * Native input is hidden from view so we can use custom checkbox design
 */
const StyledHiddenCheckbox = styled.input<CheckboxInternalProps>(() => ({
  display: 'none'
}))

const StyledCheckboxIcon = styled(Icon)<CheckboxInternalProps>(
  ({ theme, checked, disabled }) => ({
    width: 18,
    height: 18,
    border: `1px solid ${
      disabled ? theme.colors.state.disabled : theme.colors.primary
    }`,
    borderRadius: theme.radii[1],
    background: checked ? theme.colors.primary : theme.colors.white,
    use: {
      opacity: checked ? 1 : 0
    }
  })
)
