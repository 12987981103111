import { StyledBreadcrumb } from 'library/molecules/Breadcrumb/Breadcrumb'
import Link from 'next/link'
import React, { MouseEvent } from 'react'
import Icon from 'shared/library/atoms/Icon'
import { IconNames } from 'enums/iconNames'
import Button from 'library/atoms/Button'
import styled from '@emotion/styled'
import useTranslation from 'next-translate/useTranslation'

interface BackBreadcrumbProps {
  title: string
  onClick?: (param: MouseEvent) => void
  href?: string
  navIconName?: Extract<IconNames, 'arrow-left' | 'house'>
}

/**
 * BackBreadcrumb - is used typically within the context of the Header component.
 * It is used when we want to ONLY support navigation to a specified screen
 * (However this would need to be the previous screen UX to make sense.)
 * Functionally, we dont want to rely on Next's functions for previous routes because
 * in some cases we want to support custom client side routing for animated
 * 'views' driven by React state)
 * Therefore view changes are driven by either an ocClick or `href` prop for
 * flexibility)
 */
const BackBreadcrumb = ({
  title,
  onClick,
  href,
  navIconName = 'arrow-left'
}: BackBreadcrumbProps) => {
  const ICON_SETTINGS = {
    name: navIconName,
    size: 24
  }
  const { t } = useTranslation('common')

  return (
    /**
     * Leveraging StyledBreadcrumb from the Breadcrumb component and associated
     * className's it acts on to keep styles consistent with our more generic
     * Breadcrumb component
     *  */
    <StyledBreadcrumb
      onClick={onClick}
      // Add aria-lable to whole component if href is missing, otherwise,
      // it is introduced at the a tag
      aria-label={href ? undefined : title}
    >
      <ul className="breadcrumb">
        <li className="breadcrumb-item">
          {!onClick && href ? (
            <Link
              href={href}
              className="breadcrumb-link"
              aria-label={t('back-to-previous')}
            >
              <StyledIconWrapper variant="icon" as="span" mr={2}>
                <Icon {...ICON_SETTINGS} />
              </StyledIconWrapper>
              <span className="breadcrumb-text">{title}</span>
            </Link>
          ) : (
            <>
              <Icon {...ICON_SETTINGS} mx={3} />
              <span className="breadcrumb-text">
                <>{title}</>
              </span>
            </>
          )}
        </li>
      </ul>
    </StyledBreadcrumb>
  )
}

export default BackBreadcrumb

const StyledIconWrapper = styled(Button)(({ theme }) => ({
  'a:hover &': {
    ...theme.mixins.iconOnHover
  }
}))
